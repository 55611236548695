/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp    :800px;
$breakpoint_tb    :800px;
$breakpoint_pc    :$breakpoint_sp + 1;


/* Color */
$c_base : #0ea7ad;/*サイトカラー*/
$c_orange : #fb7000;/*サイトカラー*/
$c_text : #000; /*テキストに使うフォントカラー*/
$c_href : #000; /*テキスト内で使うリンクカラー*/


/* font */

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

$ff_jp: 'Noto Sans JP', sans-serif;
$ff_eng: 'Lato', sans-serif;
