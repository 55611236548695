.second_header{
  @if $type == sp{
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding-right: 70px;
    .logo{
      img{
        width: 150px;;
      }
    }
    .niben{
      padding-top: 10px;
      img{
        width: 110px;
      }
    }
  }
  @if $type == pc{
    width: 96%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    .niben{
      padding-top: 7px;
    }
  }
}
.page_title_wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  @if $type == sp{
    background: url(../images/second/second_pagetitle_bg_sp.jpg) no-repeat;
    width: 100%;
    height: 155px;
    background-size: cover;
    background-position: center;
  }
  @if $type == pc{
    background: url(../images/second/second_pagetitle_bg.jpg) no-repeat;
    width: 100%;
    height: 256px;
    background-size: cover;
    background-position: center;
  }
  .title{
    color: #fff;
    position: relative;
    @if $type == sp{
      font-size: 30px;
      padding-bottom: 10px;
    }
    @if $type == pc{
      font-size: 38px;
      padding-bottom: 15px;
    }
    &:after{
      content: "";
      background: url(../images/second/title_kazari.png) no-repeat;
      width: 32px;
      height: 8px;
      background-size: cover;
      position: absolute;
      bottom: 0;
      left: calc(50% - 16px);
    }
  }
}

#contents{
  @if $type == sp{
    padding: 40px 0 70px;
  }
  @if $type == pc{
    padding: 80px 0;
  }
}
.news_list{
  @if $type == sp{
  }
  @if $type == pc{
  }
  dl{
    @if $type == sp{
      &:not(:first-of-type){
        margin-top: 25px;
      }
    }
    @if $type == pc{
      overflow: hidden;
      &:not(:first-of-type){
        margin-top: 30px;
      }
    }
    dt{
      color: #666666;
      background-color: #eceff1;
      text-align: center;
      line-height: 1.2;
      padding: 6px;
      @if $type == sp{
        display: inline-block;
        font-size: 14px;
        width: 100px;
      }
      @if $type == pc{
        width: 100px;
        font-size: 16px;
        float: left;
      }
    }
    dd{
      @if $type == sp{
        margin-top: 10px;
      }
      @if $type == pc{
        float: left;
        width: calc(100% - 100px);
        padding-left: 20px;
      }
      a{
        text-decoration: none;
        color: #000;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
.breadcrumb{
  display: flex;
  @if $type==sp {
    margin: 0 15px 10px;
  }
  @if $type==pc {
    margin: 0 auto 10px;
    width: 96%;
    max-width: 1200px;
  }
  > span{
    display: inline-block;
    position: relative;
    padding-right: 30px;
    font-size: 13px;
    color: #595757;
    &:not(:last-of-type)::after{
      content: "";
      display: inline-block;
      width: 1px;
      height: 1em;
      background-color: #595757;
      position: absolute;
      top: 3px;
      right: 12px;
    }
    a{
      text-decoration: underline;
      color: #595757;
    }
  }
}
.wp-pagenavi{
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @if $type == sp{
    margin-top: 30px;
  }
  @if $type == pc{
    margin-top: 50px;
  }
  .page,.nextpostslink,.previouspostslink,.extend,.last,.first{
    display: block;
    padding: 5px 11px;
    background: #fff;
    //border: 1px solid #b2c0ce;
    color: #000;
    text-decoration: none;
    font-size: 14px;
    margin: 0 3px;
    @if $type == sp{
    }
    @if $type == pc{
      &:hover{
        //border: 1px solid #efd800!important;
        background: $c_base;
        color: #fff;
        opacity: 1;
      }
    }
  }
  .extend{
    border: none;
    &:hover{
      background: #fff;
      opacity: 1;
      border: none !important;
      color: #000;
    }
  }
  span.current{
    display: block;
    padding: 5px 11px;
    //border: 1px solid #efd800!important;
    background-color: $c_base;
    color: #fff;
    font-size: 14px;
    margin: 0 3px;
    @if $type == sp{
    }
    @if $type == pc{
    }
  }
  .prev, &.nextpostslink{
    color: #000;
    &:hover{
      background-color: $c_base;
      color: #fff;
    }
  }
}

.activities_list{
  @if $type == sp{
  }
  @if $type == pc{
  }
  > div{
    border-bottom: 1px solid #e6e6e6;
    @if $type == sp{
      padding: 20px 0;
      display: flex;
      align-items: center;
    }
    @if $type == pc{
      padding: 20px;
      display: flex;
      align-items: center;
    }
    .img_area{
      @if $type == sp{
        width: 40%;
      }
      @if $type == pc{
        width: 220px;
      }
      img{
        width: 100%;
      }
    }
    .text_area{
      @if $type == sp{
        width: 60%;
        padding-left: 20px;
      }
      @if $type == pc{
        width: calc(100% - 220px);
        padding-left: 20px;
      }
      .ymd_tag{
        display: flex;
        align-items: center;
        .ymd{
          color: #028287;
          @if $type == sp{
            font-size: 13px;
          }
          @if $type == pc{
            font-size: 14px;
          }
        }
        .tag{
          background-color: #eceff1;
          display: inline-block;
          margin-left: 15px;
          padding: 3px 10px;
          @if $type == sp{
            font-size: 10px;
          }
          @if $type == pc{
            font-size: 11px;
          }
        }
      }
      .title{
        font-weight: bold;
        @if $type == sp{
          margin-top: 5px;
          font-size: 13px;
        }
        @if $type == pc{
          margin-top: 10px;
        }
        a{
          text-decoration: none;
          &:hover{
            text-decoration: underline;
          }
        }
      }
      .text{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @if $type == sp{
          font-size: 12px;
          margin-top: 5px;
        }
        @if $type == pc{
          font-size: 14px;
          margin-top: 10px;
        }
      }
      .btn{
        text-align: right;
        @if $type == sp{
          margin-top: 5px;
        }
        @if $type == pc{
          margin-top: 10px;
        }
        a{
          padding: 3px 17px 3px 10px;
          border: 1px solid #e6e6e6;
          text-decoration: none;
          position: relative;
          @if $type == sp{
            font-size: 10px;
          }
          @if $type == pc{
            font-size: 11px;
            &:hover{
              background-color: $c_base;
              color: #fff;
              &:after{
                border-top: solid 1px #fff;
                border-right: solid 1px #fff;
              }
            }
          }
          &:after{
            content: '';
            width: 5px;
            height: 5px;
            border: 0px;
            border-top: solid 1px #000;
            border-right: solid 1px #000;
            transform: rotate(45deg);
            position: absolute;
            top: 9px;
            right: 8px;
          }
        }
      }
    }
  }
}

@import "_common_second_style";


.sample{
  @if $type == sp{
  }
  @if $type == pc{
  }
}
