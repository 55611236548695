@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/* font */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@media screen and (max-width: 800px) {
  .second_header {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding-right: 70px; }
    .second_header .logo img {
      width: 150px; }
    .second_header .niben {
      padding-top: 10px; }
      .second_header .niben img {
        width: 110px; }
  .page_title_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../images/second/second_pagetitle_bg_sp.jpg) no-repeat;
    width: 100%;
    height: 155px;
    background-size: cover;
    background-position: center; }
    .page_title_wrap .title {
      color: #fff;
      position: relative;
      font-size: 30px;
      padding-bottom: 10px; }
      .page_title_wrap .title:after {
        content: "";
        background: url(../images/second/title_kazari.png) no-repeat;
        width: 32px;
        height: 8px;
        background-size: cover;
        position: absolute;
        bottom: 0;
        left: calc(50% - 16px); }
  #contents {
    padding: 40px 0 70px; }
  .news_list dl:not(:first-of-type) {
    margin-top: 25px; }
  .news_list dl dt {
    color: #666666;
    background-color: #eceff1;
    text-align: center;
    line-height: 1.2;
    padding: 6px;
    display: inline-block;
    font-size: 14px;
    width: 100px; }
  .news_list dl dd {
    margin-top: 10px; }
    .news_list dl dd a {
      text-decoration: none;
      color: #000; }
      .news_list dl dd a:hover {
        text-decoration: underline; }
  .breadcrumb {
    display: flex;
    margin: 0 15px 10px; }
    .breadcrumb > span {
      display: inline-block;
      position: relative;
      padding-right: 30px;
      font-size: 13px;
      color: #595757; }
      .breadcrumb > span:not(:last-of-type)::after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 1em;
        background-color: #595757;
        position: absolute;
        top: 3px;
        right: 12px; }
      .breadcrumb > span a {
        text-decoration: underline;
        color: #595757; }
  .wp-pagenavi {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px; }
    .wp-pagenavi .page, .wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink, .wp-pagenavi .extend, .wp-pagenavi .last, .wp-pagenavi .first {
      display: block;
      padding: 5px 11px;
      background: #fff;
      color: #000;
      text-decoration: none;
      font-size: 14px;
      margin: 0 3px; }
    .wp-pagenavi .extend {
      border: none; }
      .wp-pagenavi .extend:hover {
        background: #fff;
        opacity: 1;
        border: none !important;
        color: #000; }
    .wp-pagenavi span.current {
      display: block;
      padding: 5px 11px;
      background-color: #0ea7ad;
      color: #fff;
      font-size: 14px;
      margin: 0 3px; }
    .wp-pagenavi .prev, .wp-pagenavi.nextpostslink {
      color: #000; }
      .wp-pagenavi .prev:hover, .wp-pagenavi.nextpostslink:hover {
        background-color: #0ea7ad;
        color: #fff; }
  .activities_list > div {
    border-bottom: 1px solid #e6e6e6;
    padding: 20px 0;
    display: flex;
    align-items: center; }
    .activities_list > div .img_area {
      width: 40%; }
      .activities_list > div .img_area img {
        width: 100%; }
    .activities_list > div .text_area {
      width: 60%;
      padding-left: 20px; }
      .activities_list > div .text_area .ymd_tag {
        display: flex;
        align-items: center; }
        .activities_list > div .text_area .ymd_tag .ymd {
          color: #028287;
          font-size: 13px; }
        .activities_list > div .text_area .ymd_tag .tag {
          background-color: #eceff1;
          display: inline-block;
          margin-left: 15px;
          padding: 3px 10px;
          font-size: 10px; }
      .activities_list > div .text_area .title {
        font-weight: bold;
        margin-top: 5px;
        font-size: 13px; }
        .activities_list > div .text_area .title a {
          text-decoration: none; }
          .activities_list > div .text_area .title a:hover {
            text-decoration: underline; }
      .activities_list > div .text_area .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        margin-top: 5px; }
      .activities_list > div .text_area .btn {
        text-align: right;
        margin-top: 5px; }
        .activities_list > div .text_area .btn a {
          padding: 3px 17px 3px 10px;
          border: 1px solid #e6e6e6;
          text-decoration: none;
          position: relative;
          font-size: 10px; }
          .activities_list > div .text_area .btn a:after {
            content: '';
            width: 5px;
            height: 5px;
            border: 0px;
            border-top: solid 1px #000;
            border-right: solid 1px #000;
            transform: rotate(45deg);
            position: absolute;
            top: 9px;
            right: 8px; }
  .preBox {
    background: #eff0f4;
    border: 1px solid #0ea7ad;
    margin-top: 30px;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden; }
    .preBox pre {
      overflow: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align-last: auto; }
  a {
    color: #000; }
  #contents p, #contents .common_list, #contents .commonTable, #contents .img_line2, #contents .img_line3, #contents .qa_area, #contents .imgInP, #contents .btn_line2, #contents .btn_line3, #contents .btn_line4 {
    margin-top: 1em; }
  #contents p {
    line-height: 2; }
  #contents li {
    list-style: none; }
  #contents .img img {
    max-width: 100%; }
  #contents .news_h1 {
    background-color: #0ea7ad;
    color: #fff;
    font-size: 18px;
    position: relative;
    padding: 20px 15px 15px; }
    #contents .news_h1 .ymd {
      color: #fff;
      font-size: 14px;
      font-size: 12px;
      text-align: right;
      display: block; }
  #contents .h2 {
    font-weight: 700;
    position: relative;
    color: #028287;
    font-size: 23px;
    margin-top: 20px;
    padding-bottom: 15px; }
    #contents .h2:after {
      content: "";
      width: 120px;
      height: 2px;
      background-color: #0ea7ad;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0; }
  #contents .h3 {
    font-size: 18px;
    margin-top: 1em; }
    #contents .h3 + p {
      margin-top: 20px; }
  #contents .h4 {
    margin-top: 15px; }
    #contents .h4 + p {
      margin-top: 10px; }
  #contents ul.common_list li {
    position: relative;
    padding-left: 15px;
    list-style: none;
    margin-bottom: 10px; }
    #contents ul.common_list li:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #0ea7ad;
      position: absolute;
      left: 0px;
      top: 12px; }
    #contents ul.common_list li:last-child {
      margin-bottom: 0; }
  #contents a.link,
  #contents span.link {
    list-style: none;
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-right: 15px; }
    #contents a.link:after,
    #contents span.link:after {
      content: "";
      position: absolute;
      display: block;
      width: 6px;
      height: 6px;
      border-top: 2px solid #0ea7ad;
      border-right: 2px solid #0ea7ad;
      right: 0;
      top: calc(50% - 3px);
      transform: rotate(45deg); }
  #contents .ico_pdf {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../images/common/icon_pdf.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: baseline;
    margin-left: 7px;
    position: relative; }
  #contents .ico_blank {
    display: inline-block;
    width: 20px;
    height: 21px;
    background: url("../images/common/icon_blank.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 5px;
    position: relative;
    top: -1px; }
  #contents .ico_blank_w {
    display: inline-block;
    width: 20px;
    height: 21px;
    background: url("../images/common/icon_blank_w.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 7px;
    position: relative;
    top: -1px; }
  #contents .commonTable {
    border-top: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    width: 100%; }
    #contents .commonTable.fixTable {
      table-layout: fixed; }
    #contents .commonTable tr {
      border-bottom: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb; }
    #contents .commonTable td.wFix,
    #contents .commonTable th.wFix {
      width: 120px; }
    #contents .commonTable th {
      background-color: #f6f6f6;
      padding: 12px;
      text-align: left;
      font-weight: normal;
      background-clip: padding-box;
      border-right: 1px solid #ebebeb; }
      #contents .commonTable th.required {
        position: relative;
        vertical-align: middle; }
        #contents .commonTable th.required:after {
          content: "必須";
          background-color: #cf0000;
          color: #fff;
          font-size: 14px;
          padding: 4px 8px;
          line-height: 1;
          display: inline-block;
          float: right;
          margin-right: -35px; }
      #contents .commonTable th.sub {
        background-color: #cbcbcb; }
      #contents .commonTable th.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        background-color: #fff; }
      #contents .commonTable th.bgblue {
        background-color: #eef8ff; }
    #contents .commonTable td {
      background-color: #fff;
      padding: 12px;
      border-right: 1px solid #ebebeb;
      vertical-align: top; }
      #contents .commonTable td *:last-child {
        margin-bottom: 0; }
      #contents .commonTable td *:first-child {
        margin-top: 0; }
      #contents .commonTable td .kome {
        display: block;
        margin-top: 5px; }
      #contents .commonTable td .category {
        width: 130px;
        display: inline-block; }
      #contents .commonTable td .subtxt {
        width: 43px;
        display: inline-block;
        text-align: right;
        padding-right: 10px; }
      #contents .commonTable td.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff; }
      #contents .commonTable td.bgblue {
        background-color: #eef8ff; }
    #contents .commonTable.company {
      border-left: none;
      border-top: none; }
      #contents .commonTable.company tr {
        border-right: none; }
      #contents .commonTable.company th {
        border-right: none;
        background-color: #fff;
        font-weight: bold;
        display: block;
        padding: 15px 0 0; }
      #contents .commonTable.company td {
        border-right: none;
        display: block;
        padding: 6px 0 15px; }
    #contents .commonTable.type2 {
      border-left: none; }
      #contents .commonTable.type2 th.title {
        text-align: left;
        border-right: none;
        background-color: #f6f6f6; }
        #contents .commonTable.type2 th.title:after {
          content: none; }
      #contents .commonTable.type2 tr {
        border-right: none; }
      #contents .commonTable.type2 th {
        background: none;
        border-right: none;
        position: relative;
        width: 120px; }
        #contents .commonTable.type2 th:after {
          content: ":";
          color: #999999;
          display: inline-block;
          position: absolute;
          right: 0; }
      #contents .commonTable.type2 td {
        border-right: none; }
  #contents .scrollTable {
    overflow: auto; }
    #contents .scrollTable table {
      white-space: nowrap; }
  #contents .scrolltext {
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
    text-align: right; }
    #contents .scrolltext:before {
      content: "";
      background: url(../images/common/icon_scroll.png) no-repeat;
      width: 16px;
      height: 14px;
      background-size: contain;
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle; }
  #contents .common_btn {
    color: #fff; }
    #contents .common_btn[target="_blank"]:before, #contents .common_btn[target="newwindow"]:before {
      content: "";
      background: url(../images/common/icon_blank.png) no-repeat;
      background-size: cover;
      width: 16px;
      height: 16px;
      display: inline-block;
      position: absolute;
      top: 12px;
      right: 12px; }
    #contents .common_btn[href$=".pdf"]:after {
      content: none; }
  #contents .img_line2.spflex {
    display: flex;
    justify-content: center; }
  #contents .img_line2 li {
    text-align: center;
    margin-bottom: 15px; }
    #contents .img_line2 li img {
      max-width: 100%; }
  #contents .img_line3 li {
    text-align: center;
    margin-bottom: 15px; }
    #contents .img_line3 li img {
      max-width: 100%; }
  #contents .imgInP .img {
    display: block;
    margin: 0 auto 20px; }
    #contents .imgInP .img .cap {
      display: block;
      text-align: left;
      font-size: 14px;
      margin-top: 10px; }
  #contents .imgInP.col2 .text {
    margin-bottom: 20px;
    display: block; }
    #contents .imgInP.col2 .text .h3:first-of-type {
      margin-top: 0; }
    #contents .imgInP.col2 .text .h3 + .commonTable {
      margin-top: 30px;
      margin-top: 1em; }
  #contents .imgInP.col2 .img {
    max-width: 100%; }
  #contents .imgInP.sp_img_btm {
    display: flex;
    flex-wrap: wrap; }
    #contents .imgInP.sp_img_btm .img {
      order: 2;
      margin: 20px auto 0; }
    #contents .imgInP.sp_img_btm .text {
      flex-basis: 100%;
      order: 1; }
  #contents .color_box {
    background-color: #f2f5f5;
    padding: 40px 20px 30px;
    margin-top: 10px;
    margin-top: 30px; }
    #contents .color_box .title {
      font-size: 20px;
      margin-top: -55px;
      font-weight: bold;
      padding: 0 15px; }
    #contents .color_box .ss_service_list {
      padding: 0 15px;
      margin-top: 1em; }
  /* common class */
  .pointerNone {
    cursor: default;
    text-decoration: none; }
  .alignC {
    text-align: center !important; }
  .alignL {
    text-align: left !important; }
  .alignR {
    text-align: right !important; }
  .Center {
    margin: 0 auto !important; }
  .ovh {
    overflow: hidden !important; }
  .mw100 {
    max-width: 100% !important;
    width: auto !important; }
  .w100p {
    width: 100% !important; }
  .bold {
    font-weight: bold !important; }
  .textred {
    color: red; }
  .textblue {
    color: blue; }
  .floatL {
    float: left; }
  .floatR {
    float: right; }
  .red {
    color: #0ea7ad; }
  .mB0 {
    margin-bottom: 0 !important; }
  .mB5 {
    margin-bottom: 5px !important; }
  .mB10 {
    margin-bottom: 10px !important; }
  .mB20 {
    margin-bottom: 20px !important; }
  .mB30 {
    margin-bottom: 30px !important; }
  .mB40 {
    margin-bottom: 40px !important; }
  .mB50 {
    margin-bottom: 50px !important; }
  .mT0 {
    margin-top: 0 !important; }
  .mT5 {
    margin-top: 5px !important; }
  .mT10 {
    margin-top: 10px !important; }
  .mT20 {
    margin-top: 20px !important; }
  .mT30 {
    margin-top: 30px !important; }
  .mT40 {
    margin-top: 40px !important; }
  .mT50 {
    margin-top: 50px !important; }
  .mL0 {
    margin-left: 0px !important; }
  .mL5 {
    margin-left: 5px !important; }
  .mL10 {
    margin-left: 10px !important; }
  .mL20 {
    margin-left: 20px !important; }
  .mL30 {
    margin-left: 30px !important; }
  .mL40 {
    margin-left: 40px !important; }
  .mL50 {
    margin-left: 50px !important; }
  .mR0 {
    margin-right: 0px !important; }
  .mR5 {
    margin-right: 5px !important; }
  .mR10 {
    margin-right: 10px !important; }
  .mR20 {
    margin-right: 20px !important; }
  .mR30 {
    margin-right: 30px !important; }
  .mR40 {
    margin-right: 40px !important; }
  .mR50 {
    margin-right: 50px !important; } }

@media screen and (min-width: 800px) {
  .page_title_wrap {
    display: flex;
    align-items: center;
    justify-content: center; }
    .page_title_wrap .title {
      color: #fff;
      position: relative; }
      .page_title_wrap .title:after {
        content: "";
        background: url(../images/second/title_kazari.png) no-repeat;
        width: 32px;
        height: 8px;
        background-size: cover;
        position: absolute;
        bottom: 0;
        left: calc(50% - 16px); }
  .news_list dl dt {
    color: #666666;
    background-color: #eceff1;
    text-align: center;
    line-height: 1.2;
    padding: 6px; }
  .news_list dl dd a {
    text-decoration: none;
    color: #000; }
    .news_list dl dd a:hover {
      text-decoration: underline; }
  .breadcrumb {
    display: flex; }
    .breadcrumb > span {
      display: inline-block;
      position: relative;
      padding-right: 30px;
      font-size: 13px;
      color: #595757; }
      .breadcrumb > span:not(:last-of-type)::after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 1em;
        background-color: #595757;
        position: absolute;
        top: 3px;
        right: 12px; }
      .breadcrumb > span a {
        text-decoration: underline;
        color: #595757; }
  .wp-pagenavi {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .wp-pagenavi .page, .wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink, .wp-pagenavi .extend, .wp-pagenavi .last, .wp-pagenavi .first {
      display: block;
      padding: 5px 11px;
      background: #fff;
      color: #000;
      text-decoration: none;
      font-size: 14px;
      margin: 0 3px; }
    .wp-pagenavi .extend {
      border: none; }
      .wp-pagenavi .extend:hover {
        background: #fff;
        opacity: 1;
        border: none !important;
        color: #000; }
    .wp-pagenavi span.current {
      display: block;
      padding: 5px 11px;
      background-color: #0ea7ad;
      color: #fff;
      font-size: 14px;
      margin: 0 3px; }
    .wp-pagenavi .prev, .wp-pagenavi.nextpostslink {
      color: #000; }
      .wp-pagenavi .prev:hover, .wp-pagenavi.nextpostslink:hover {
        background-color: #0ea7ad;
        color: #fff; }
  .activities_list > div {
    border-bottom: 1px solid #e6e6e6; }
    .activities_list > div .img_area img {
      width: 100%; }
    .activities_list > div .text_area .ymd_tag {
      display: flex;
      align-items: center; }
      .activities_list > div .text_area .ymd_tag .ymd {
        color: #028287; }
      .activities_list > div .text_area .ymd_tag .tag {
        background-color: #eceff1;
        display: inline-block;
        margin-left: 15px;
        padding: 3px 10px; }
    .activities_list > div .text_area .title {
      font-weight: bold; }
      .activities_list > div .text_area .title a {
        text-decoration: none; }
        .activities_list > div .text_area .title a:hover {
          text-decoration: underline; }
    .activities_list > div .text_area .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .activities_list > div .text_area .btn {
      text-align: right; }
      .activities_list > div .text_area .btn a {
        padding: 3px 17px 3px 10px;
        border: 1px solid #e6e6e6;
        text-decoration: none;
        position: relative; }
        .activities_list > div .text_area .btn a:after {
          content: '';
          width: 5px;
          height: 5px;
          border: 0px;
          border-top: solid 1px #000;
          border-right: solid 1px #000;
          transform: rotate(45deg);
          position: absolute;
          top: 9px;
          right: 8px; }
  .preBox {
    background: #eff0f4;
    border: 1px solid #0ea7ad;
    margin-top: 30px;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden; }
    .preBox pre {
      overflow: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align-last: auto; }
  a {
    color: #000; }
  #contents p {
    line-height: 2; }
  #contents li {
    list-style: none; }
  #contents .img img {
    max-width: 100%; }
  #contents .news_h1 {
    background-color: #0ea7ad;
    color: #fff; }
    #contents .news_h1 .ymd {
      color: #fff;
      font-size: 14px; }
  #contents .h2 {
    font-weight: 700;
    position: relative;
    color: #028287; }
    #contents .h2:after {
      content: "";
      width: 120px;
      height: 2px;
      background-color: #0ea7ad;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0; }
  #contents .h4 + p {
    margin-top: 10px; }
  #contents ul.common_list li {
    position: relative;
    padding-left: 15px;
    list-style: none;
    margin-bottom: 10px; }
    #contents ul.common_list li:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #0ea7ad;
      position: absolute;
      left: 0px;
      top: 12px; }
    #contents ul.common_list li:last-child {
      margin-bottom: 0; }
  #contents a.link,
  #contents span.link {
    list-style: none;
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-right: 15px; }
    #contents a.link:after,
    #contents span.link:after {
      content: "";
      position: absolute;
      display: block;
      width: 6px;
      height: 6px;
      border-top: 2px solid #0ea7ad;
      border-right: 2px solid #0ea7ad;
      right: 0;
      top: calc(50% - 3px);
      transform: rotate(45deg); }
  #contents .ico_pdf {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../images/common/icon_pdf.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: baseline;
    margin-left: 7px;
    position: relative; }
  #contents .ico_blank {
    display: inline-block;
    width: 20px;
    height: 21px;
    background: url("../images/common/icon_blank.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 5px;
    position: relative;
    top: -1px; }
  #contents .ico_blank_w {
    display: inline-block;
    width: 20px;
    height: 21px;
    background: url("../images/common/icon_blank_w.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 7px;
    position: relative;
    top: -1px; }
  #contents .commonTable {
    border-top: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    width: 100%; }
    #contents .commonTable tr {
      border-bottom: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb; }
    #contents .commonTable th {
      background-color: #f6f6f6;
      padding: 12px;
      text-align: left;
      font-weight: normal;
      background-clip: padding-box;
      border-right: 1px solid #ebebeb; }
      #contents .commonTable th.required {
        position: relative;
        vertical-align: middle; }
        #contents .commonTable th.required:after {
          content: "必須";
          background-color: #cf0000;
          color: #fff;
          font-size: 14px;
          padding: 4px 8px;
          line-height: 1;
          display: inline-block;
          float: right;
          margin-right: -35px; }
      #contents .commonTable th.sub {
        background-color: #cbcbcb; }
      #contents .commonTable th.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        background-color: #fff; }
      #contents .commonTable th.bgblue {
        background-color: #eef8ff; }
    #contents .commonTable td {
      background-color: #fff;
      padding: 12px;
      border-right: 1px solid #ebebeb;
      vertical-align: top; }
      #contents .commonTable td *:last-child {
        margin-bottom: 0; }
      #contents .commonTable td *:first-child {
        margin-top: 0; }
      #contents .commonTable td .kome {
        display: block;
        margin-top: 5px; }
      #contents .commonTable td .category {
        width: 130px;
        display: inline-block; }
      #contents .commonTable td .subtxt {
        width: 43px;
        display: inline-block;
        text-align: right;
        padding-right: 10px; }
      #contents .commonTable td.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff; }
      #contents .commonTable td.bgblue {
        background-color: #eef8ff; }
    #contents .commonTable.company {
      border-left: none;
      border-top: none; }
      #contents .commonTable.company tr {
        border-right: none; }
      #contents .commonTable.company th {
        border-right: none;
        background-color: #fff;
        font-weight: bold; }
      #contents .commonTable.company td {
        border-right: none; }
    #contents .commonTable.type2 {
      border-left: none; }
      #contents .commonTable.type2 th.title {
        text-align: left;
        border-right: none;
        background-color: #f6f6f6; }
        #contents .commonTable.type2 th.title:after {
          content: none; }
      #contents .commonTable.type2 tr {
        border-right: none; }
      #contents .commonTable.type2 th {
        background: none;
        border-right: none;
        position: relative; }
        #contents .commonTable.type2 th:after {
          content: ":";
          color: #999999;
          display: inline-block;
          position: absolute;
          right: 0; }
      #contents .commonTable.type2 td {
        border-right: none; }
  #contents .scrollTable {
    overflow: auto; }
  #contents .scrolltext {
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
    text-align: right; }
    #contents .scrolltext:before {
      content: "";
      background: url(../images/common/icon_scroll.png) no-repeat;
      width: 16px;
      height: 14px;
      background-size: contain;
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle; }
  #contents .common_btn {
    color: #fff; }
    #contents .common_btn[target="_blank"]:before, #contents .common_btn[target="newwindow"]:before {
      content: "";
      background: url(../images/common/icon_blank.png) no-repeat;
      background-size: cover;
      width: 16px;
      height: 16px;
      display: inline-block;
      position: absolute;
      top: 12px;
      right: 12px; }
    #contents .common_btn[href$=".pdf"]:after {
      content: none; }
  #contents .img_line2 li {
    text-align: center; }
    #contents .img_line2 li img {
      max-width: 100%; }
  #contents .img_line3 li {
    text-align: center; }
    #contents .img_line3 li img {
      max-width: 100%; }
  #contents .imgInP .img .cap {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-top: 10px; }
  #contents .imgInP.col2 .text .h3:first-of-type {
    margin-top: 0; }
  #contents .imgInP.col2 .text .h3 + .commonTable {
    margin-top: 30px; }
  #contents .imgInP.col2 .img {
    max-width: 100%; }
  #contents .color_box {
    background-color: #f2f5f5;
    padding: 40px 20px 30px;
    margin-top: 10px; }
    #contents .color_box .title {
      font-size: 20px;
      margin-top: -55px;
      font-weight: bold;
      padding: 0 15px; }
    #contents .color_box .ss_service_list {
      padding: 0 15px;
      margin-top: 1em; }
  /* common class */
  .pointerNone {
    cursor: default;
    text-decoration: none; }
  .alignC {
    text-align: center !important; }
  .alignL {
    text-align: left !important; }
  .alignR {
    text-align: right !important; }
  .Center {
    margin: 0 auto !important; }
  .ovh {
    overflow: hidden !important; }
  .mw100 {
    max-width: 100% !important;
    width: auto !important; }
  .w100p {
    width: 100% !important; }
  .bold {
    font-weight: bold !important; }
  .textred {
    color: red; }
  .textblue {
    color: blue; }
  .floatL {
    float: left; }
  .floatR {
    float: right; }
  .red {
    color: #0ea7ad; }
  .mB0 {
    margin-bottom: 0 !important; }
  .mB5 {
    margin-bottom: 5px !important; }
  .mB10 {
    margin-bottom: 10px !important; }
  .mB20 {
    margin-bottom: 20px !important; }
  .mB30 {
    margin-bottom: 30px !important; }
  .mB40 {
    margin-bottom: 40px !important; }
  .mB50 {
    margin-bottom: 50px !important; }
  .mT0 {
    margin-top: 0 !important; }
  .mT5 {
    margin-top: 5px !important; }
  .mT10 {
    margin-top: 10px !important; }
  .mT20 {
    margin-top: 20px !important; }
  .mT30 {
    margin-top: 30px !important; }
  .mT40 {
    margin-top: 40px !important; }
  .mT50 {
    margin-top: 50px !important; }
  .mL0 {
    margin-left: 0px !important; }
  .mL5 {
    margin-left: 5px !important; }
  .mL10 {
    margin-left: 10px !important; }
  .mL20 {
    margin-left: 20px !important; }
  .mL30 {
    margin-left: 30px !important; }
  .mL40 {
    margin-left: 40px !important; }
  .mL50 {
    margin-left: 50px !important; }
  .mR0 {
    margin-right: 0px !important; }
  .mR5 {
    margin-right: 5px !important; }
  .mR10 {
    margin-right: 10px !important; }
  .mR20 {
    margin-right: 20px !important; }
  .mR30 {
    margin-right: 30px !important; }
  .mR40 {
    margin-right: 40px !important; }
  .mR50 {
    margin-right: 50px !important; } }

@media print, screen and (min-width: 801px) {
  .second_header {
    width: 96%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0; }
    .second_header .niben {
      padding-top: 7px; }
  .page_title_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../images/second/second_pagetitle_bg.jpg) no-repeat;
    width: 100%;
    height: 256px;
    background-size: cover;
    background-position: center; }
    .page_title_wrap .title {
      color: #fff;
      position: relative;
      font-size: 38px;
      padding-bottom: 15px; }
      .page_title_wrap .title:after {
        content: "";
        background: url(../images/second/title_kazari.png) no-repeat;
        width: 32px;
        height: 8px;
        background-size: cover;
        position: absolute;
        bottom: 0;
        left: calc(50% - 16px); }
  #contents {
    padding: 80px 0; }
  .news_list dl {
    overflow: hidden; }
    .news_list dl:not(:first-of-type) {
      margin-top: 30px; }
    .news_list dl dt {
      color: #666666;
      background-color: #eceff1;
      text-align: center;
      line-height: 1.2;
      padding: 6px;
      width: 100px;
      font-size: 16px;
      float: left; }
    .news_list dl dd {
      float: left;
      width: calc(100% - 100px);
      padding-left: 20px; }
      .news_list dl dd a {
        text-decoration: none;
        color: #000; }
        .news_list dl dd a:hover {
          text-decoration: underline; }
  .breadcrumb {
    display: flex;
    margin: 0 auto 10px;
    width: 96%;
    max-width: 1200px; }
    .breadcrumb > span {
      display: inline-block;
      position: relative;
      padding-right: 30px;
      font-size: 13px;
      color: #595757; }
      .breadcrumb > span:not(:last-of-type)::after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 1em;
        background-color: #595757;
        position: absolute;
        top: 3px;
        right: 12px; }
      .breadcrumb > span a {
        text-decoration: underline;
        color: #595757; }
  .wp-pagenavi {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px; }
    .wp-pagenavi .page, .wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink, .wp-pagenavi .extend, .wp-pagenavi .last, .wp-pagenavi .first {
      display: block;
      padding: 5px 11px;
      background: #fff;
      color: #000;
      text-decoration: none;
      font-size: 14px;
      margin: 0 3px; }
      .wp-pagenavi .page:hover, .wp-pagenavi .nextpostslink:hover, .wp-pagenavi .previouspostslink:hover, .wp-pagenavi .extend:hover, .wp-pagenavi .last:hover, .wp-pagenavi .first:hover {
        background: #0ea7ad;
        color: #fff;
        opacity: 1; }
    .wp-pagenavi .extend {
      border: none; }
      .wp-pagenavi .extend:hover {
        background: #fff;
        opacity: 1;
        border: none !important;
        color: #000; }
    .wp-pagenavi span.current {
      display: block;
      padding: 5px 11px;
      background-color: #0ea7ad;
      color: #fff;
      font-size: 14px;
      margin: 0 3px; }
    .wp-pagenavi .prev, .wp-pagenavi.nextpostslink {
      color: #000; }
      .wp-pagenavi .prev:hover, .wp-pagenavi.nextpostslink:hover {
        background-color: #0ea7ad;
        color: #fff; }
  .activities_list > div {
    border-bottom: 1px solid #e6e6e6;
    padding: 20px;
    display: flex;
    align-items: center; }
    .activities_list > div .img_area {
      width: 220px; }
      .activities_list > div .img_area img {
        width: 100%; }
    .activities_list > div .text_area {
      width: calc(100% - 220px);
      padding-left: 20px; }
      .activities_list > div .text_area .ymd_tag {
        display: flex;
        align-items: center; }
        .activities_list > div .text_area .ymd_tag .ymd {
          color: #028287;
          font-size: 14px; }
        .activities_list > div .text_area .ymd_tag .tag {
          background-color: #eceff1;
          display: inline-block;
          margin-left: 15px;
          padding: 3px 10px;
          font-size: 11px; }
      .activities_list > div .text_area .title {
        font-weight: bold;
        margin-top: 10px; }
        .activities_list > div .text_area .title a {
          text-decoration: none; }
          .activities_list > div .text_area .title a:hover {
            text-decoration: underline; }
      .activities_list > div .text_area .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        margin-top: 10px; }
      .activities_list > div .text_area .btn {
        text-align: right;
        margin-top: 10px; }
        .activities_list > div .text_area .btn a {
          padding: 3px 17px 3px 10px;
          border: 1px solid #e6e6e6;
          text-decoration: none;
          position: relative;
          font-size: 11px; }
          .activities_list > div .text_area .btn a:hover {
            background-color: #0ea7ad;
            color: #fff; }
            .activities_list > div .text_area .btn a:hover:after {
              border-top: solid 1px #fff;
              border-right: solid 1px #fff; }
          .activities_list > div .text_area .btn a:after {
            content: '';
            width: 5px;
            height: 5px;
            border: 0px;
            border-top: solid 1px #000;
            border-right: solid 1px #000;
            transform: rotate(45deg);
            position: absolute;
            top: 9px;
            right: 8px; }
  .preBox {
    background: #eff0f4;
    border: 1px solid #0ea7ad;
    margin-top: 30px;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden; }
    .preBox pre {
      overflow: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align-last: auto; }
  a {
    color: #000; }
  #contents p, #contents .common_list, #contents .commonTable, #contents .img_line2, #contents .img_line3, #contents .qa_area, #contents .imgInP, #contents .btn_line2, #contents .btn_line3, #contents .btn_line4 {
    margin-top: 2em; }
  #contents p {
    line-height: 2; }
  #contents li {
    list-style: none; }
  #contents .img img {
    max-width: 100%; }
  #contents .news_h1 {
    background-color: #0ea7ad;
    color: #fff;
    font-size: 24px;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    #contents .news_h1 .ymd {
      color: #fff;
      font-size: 14px; }
  #contents .h2 {
    font-weight: 700;
    position: relative;
    color: #028287;
    font-size: 34px;
    margin-top: 40px;
    padding-bottom: 20px; }
    #contents .h2:after {
      content: "";
      width: 120px;
      height: 2px;
      background-color: #0ea7ad;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0; }
  #contents .h3 {
    font-size: 24px;
    margin-top: 2em; }
    #contents .h3 + p {
      margin-top: 30px; }
  #contents .h4 {
    margin-top: 30px; }
    #contents .h4 + p {
      margin-top: 10px; }
  #contents ul.common_list li {
    position: relative;
    padding-left: 15px;
    list-style: none;
    margin-bottom: 10px; }
    #contents ul.common_list li:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #0ea7ad;
      position: absolute;
      left: 0px;
      top: 12px; }
    #contents ul.common_list li:last-child {
      margin-bottom: 0; }
  #contents a.link,
  #contents span.link {
    list-style: none;
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-right: 15px; }
    #contents a.link:after,
    #contents span.link:after {
      content: "";
      position: absolute;
      display: block;
      width: 6px;
      height: 6px;
      border-top: 2px solid #0ea7ad;
      border-right: 2px solid #0ea7ad;
      right: 0;
      top: calc(50% - 3px);
      transform: rotate(45deg); }
  #contents .ico_pdf {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../images/common/icon_pdf.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: baseline;
    margin-left: 7px;
    position: relative; }
  #contents .ico_blank {
    display: inline-block;
    width: 20px;
    height: 21px;
    background: url("../images/common/icon_blank.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 5px;
    position: relative;
    top: -1px; }
  #contents .ico_blank_w {
    display: inline-block;
    width: 20px;
    height: 21px;
    background: url("../images/common/icon_blank_w.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 7px;
    position: relative;
    top: -1px; }
  #contents .commonTable {
    border-top: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    width: 100%; }
    #contents .commonTable tr {
      border-bottom: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb; }
    #contents .commonTable td.wFix,
    #contents .commonTable th.wFix {
      width: 150px; }
    #contents .commonTable th {
      background-color: #f6f6f6;
      padding: 12px;
      text-align: left;
      font-weight: normal;
      background-clip: padding-box;
      border-right: 1px solid #ebebeb; }
      #contents .commonTable th.required {
        position: relative;
        vertical-align: middle; }
        #contents .commonTable th.required:after {
          content: "必須";
          background-color: #cf0000;
          color: #fff;
          font-size: 14px;
          padding: 4px 8px;
          line-height: 1;
          display: inline-block;
          float: right;
          margin-right: -35px; }
      #contents .commonTable th.sub {
        background-color: #cbcbcb; }
      #contents .commonTable th.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        background-color: #fff; }
      #contents .commonTable th.bgblue {
        background-color: #eef8ff; }
    #contents .commonTable td {
      background-color: #fff;
      padding: 12px;
      border-right: 1px solid #ebebeb;
      vertical-align: top; }
      #contents .commonTable td *:last-child {
        margin-bottom: 0; }
      #contents .commonTable td *:first-child {
        margin-top: 0; }
      #contents .commonTable td .kome {
        display: block;
        margin-top: 5px; }
      #contents .commonTable td .category {
        width: 130px;
        display: inline-block; }
      #contents .commonTable td .subtxt {
        width: 43px;
        display: inline-block;
        text-align: right;
        padding-right: 10px; }
      #contents .commonTable td.null {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff; }
      #contents .commonTable td.bgblue {
        background-color: #eef8ff; }
    #contents .commonTable.company {
      border-left: none;
      border-top: none; }
      #contents .commonTable.company tr {
        border-right: none; }
      #contents .commonTable.company th {
        border-right: none;
        background-color: #fff;
        font-weight: bold; }
      #contents .commonTable.company td {
        border-right: none; }
    #contents .commonTable.type2 {
      border-left: none; }
      #contents .commonTable.type2 th.title {
        text-align: left;
        border-right: none;
        background-color: #f6f6f6; }
        #contents .commonTable.type2 th.title:after {
          content: none; }
      #contents .commonTable.type2 tr {
        border-right: none; }
      #contents .commonTable.type2 th {
        background: none;
        border-right: none;
        position: relative;
        width: 170px; }
        #contents .commonTable.type2 th:after {
          content: ":";
          color: #999999;
          display: inline-block;
          position: absolute;
          right: 0; }
      #contents .commonTable.type2 td {
        border-right: none; }
  #contents .scrollTable {
    overflow: auto; }
  #contents .scrolltext {
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
    text-align: right; }
    #contents .scrolltext:before {
      content: "";
      background: url(../images/common/icon_scroll.png) no-repeat;
      width: 16px;
      height: 14px;
      background-size: contain;
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle; }
  #contents .common_btn {
    color: #fff; }
    #contents .common_btn[target="_blank"]:before, #contents .common_btn[target="newwindow"]:before {
      content: "";
      background: url(../images/common/icon_blank.png) no-repeat;
      background-size: cover;
      width: 16px;
      height: 16px;
      display: inline-block;
      position: absolute;
      top: 12px;
      right: 12px; }
    #contents .common_btn[href$=".pdf"]:after {
      content: none; }
  #contents .img_line2 {
    display: flex;
    justify-content: center; }
    #contents .img_line2 li {
      text-align: center;
      width: calc(50% - 8px); }
      #contents .img_line2 li:nth-of-type(odd) {
        margin-right: 16px; }
      #contents .img_line2 li img {
        max-width: 100%; }
  #contents .img_line3 {
    display: flex;
    justify-content: center; }
    #contents .img_line3 li {
      text-align: center;
      width: calc(33% - 8px);
      margin-right: 16px; }
      #contents .img_line3 li:nth-of-type(3n) {
        margin-right: 0; }
      #contents .img_line3 li img {
        max-width: 100%; }
  #contents .imgInP {
    overflow: hidden; }
    #contents .imgInP .img {
      max-width: 480px; }
      #contents .imgInP .img img {
        width: 100%; }
      #contents .imgInP .img .cap {
        display: block;
        text-align: left;
        font-size: 14px;
        margin-top: 10px; }
    #contents .imgInP .img.left {
      float: left;
      margin-right: 30px;
      margin-bottom: 15px; }
    #contents .imgInP .img.right {
      float: right;
      margin-left: 30px;
      margin-bottom: 15px; }
    #contents .imgInP.col2 {
      overflow: hidden; }
      #contents .imgInP.col2 .text {
        float: left;
        width: calc(50% - 30px);
        display: block; }
        #contents .imgInP.col2 .text.left {
          float: left;
          margin-right: 30px; }
        #contents .imgInP.col2 .text.right {
          float: right;
          margin-left: 30px; }
        #contents .imgInP.col2 .text .h3:first-of-type {
          margin-top: 0; }
        #contents .imgInP.col2 .text .h3 + .commonTable {
          margin-top: 30px; }
      #contents .imgInP.col2 .img {
        max-width: 100%;
        width: calc(50% - 30px); }
  #contents .color_box {
    background-color: #f2f5f5;
    padding: 40px 20px 30px;
    margin-top: 10px; }
    #contents .color_box .title {
      font-size: 20px;
      margin-top: -55px;
      font-weight: bold;
      padding: 0 15px; }
    #contents .color_box .ss_service_list {
      padding: 0 15px;
      margin-top: 1em; }
  /* common class */
  .pointerNone {
    cursor: default;
    text-decoration: none; }
  .alignC {
    text-align: center !important; }
  .alignL {
    text-align: left !important; }
  .alignR {
    text-align: right !important; }
  .Center {
    margin: 0 auto !important; }
  .ovh {
    overflow: hidden !important; }
  .mw100 {
    max-width: 100% !important;
    width: auto !important; }
  .w100p {
    width: 100% !important; }
  .bold {
    font-weight: bold !important; }
  .textred {
    color: red; }
  .textblue {
    color: blue; }
  .floatL {
    float: left; }
  .floatR {
    float: right; }
  .red {
    color: #0ea7ad; }
  .mB0 {
    margin-bottom: 0 !important; }
  .mB5 {
    margin-bottom: 5px !important; }
  .mB10 {
    margin-bottom: 10px !important; }
  .mB20 {
    margin-bottom: 20px !important; }
  .mB30 {
    margin-bottom: 30px !important; }
  .mB40 {
    margin-bottom: 40px !important; }
  .mB50 {
    margin-bottom: 50px !important; }
  .mT0 {
    margin-top: 0 !important; }
  .mT5 {
    margin-top: 5px !important; }
  .mT10 {
    margin-top: 10px !important; }
  .mT20 {
    margin-top: 20px !important; }
  .mT30 {
    margin-top: 30px !important; }
  .mT40 {
    margin-top: 40px !important; }
  .mT50 {
    margin-top: 50px !important; }
  .mL0 {
    margin-left: 0px !important; }
  .mL5 {
    margin-left: 5px !important; }
  .mL10 {
    margin-left: 10px !important; }
  .mL20 {
    margin-left: 20px !important; }
  .mL30 {
    margin-left: 30px !important; }
  .mL40 {
    margin-left: 40px !important; }
  .mL50 {
    margin-left: 50px !important; }
  .mR0 {
    margin-right: 0px !important; }
  .mR5 {
    margin-right: 5px !important; }
  .mR10 {
    margin-right: 10px !important; }
  .mR20 {
    margin-right: 20px !important; }
  .mR30 {
    margin-right: 30px !important; }
  .mR40 {
    margin-right: 40px !important; }
  .mR50 {
    margin-right: 50px !important; } }
