.preBox {
  background: #eff0f4;
  border: 1px solid $c_base;
  margin-top: 30px;
  padding: 15px;
  box-sizing: border-box;
  clear: both;
  overflow: hidden;
  pre{
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align-last: auto;
  }
}
a{
  color: #000;
}
#contents{
  p,.common_list,.commonTable,.img_line2,.img_line3,.qa_area,.imgInP,.btn_line2,.btn_line3,.btn_line4{
    @if $type == sp{
      margin-top: 1em;
    }
    @if $type == pc{
      margin-top: 2em;
    }
  }
  p{
    line-height: 2;
  }
  li{
    list-style: none;
  }
  .img img{
    max-width: 100%;
  }
  .news_h1{
    background-color: $c_base;
    color: #fff;
    @if $type == sp{
      font-size: 18px;
      position: relative;
      padding: 20px 15px 15px;
    }
    @if $type == pc{
      font-size: 24px;
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .ymd{
      color: #fff;
      font-size: 14px;
      @if $type == sp{
        font-size: 12px;
        text-align: right;
        display: block;
      }
    }
  }
  .h2{
    font-weight: 700;
    position: relative;
    color: #028287;
    @if $type == sp{
      font-size: 23px;
      margin-top: 20px;
      padding-bottom: 15px;
    }
    @if $type == pc{
      font-size: 34px;
      margin-top: 40px;
      padding-bottom: 20px;
    }
    &:after{
      content: "";
      width: 120px;
      height: 2px;
      background-color: $c_base;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .h3{
    @if $type == sp{
      font-size: 18px;
      margin-top: 1em;
      + p{
        margin-top: 20px;
      }
    }
    @if $type == pc{
      font-size: 24px;
      margin-top: 2em;
      + p{
        margin-top: 30px;
      }
    }
  }
  .h4{
    @if $type == sp{
      margin-top: 15px;
    }
    @if $type == pc{
      margin-top: 30px;
    }
    + p{
      margin-top: 10px;
    }
  }

  ul.common_list{
    li{
      position: relative;
      padding-left: 15px;
      list-style: none;
      margin-bottom: 10px;
      &:before{
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background:$c_base;
        position: absolute;
        left:0px;
        top: 12px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  a.link,
  span.link{
    list-style: none;
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-right: 15px;
    &:after{
      content:"";
      position: absolute;
      display: block;
      width: 6px;
      height: 6px;
      border-top: 2px solid $c_base;
      border-right: 2px solid $c_base;
      right:0;
      top: calc(50% - 3px);
      transform:rotate(45deg);
    }
  }

  .ico_pdf{
    display: inline-block;
    width: 20px;
    height: 20px;
    background:url("../images/common/icon_pdf.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: baseline;
    margin-left: 7px;
    position: relative;
  }

  .ico_blank{
    display: inline-block;
    width: 20px;
    height: 21px;
    background:url("../images/common/icon_blank.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 5px;
    position: relative;
    top: -1px;
  }
  .ico_blank_w{
    display: inline-block;
    width: 20px;
    height: 21px;
    background:url("../images/common/icon_blank_w.png") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 7px;
    position: relative;
    top: -1px;
  }

  //テーブル

  .commonTable{
    border-top: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    width: 100%;

    &.fixTable{
      @if $type == sp{
        table-layout: fixed;
      }
      @if $type == pc{
      }
    }

    tr{
      border-bottom: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
    }
    td.wFix,
    th.wFix{
      @if $type == sp{
        width: 120px;
      }
      @if $type == pc{
        width: 150px;
      }
    }
    th{
      background-color: #f6f6f6;
      padding: 12px;
      //text-align: center;
      text-align: left;
      font-weight: normal;
      background-clip: padding-box;
      border-right: 1px solid #ebebeb;

      &.required{
        position: relative;
        vertical-align: middle;

        &:after{
          content: "必須";
          background-color: #cf0000;
          color: #fff;
          font-size: 14px;
          padding: 4px 8px;
          line-height: 1;
          display: inline-block;
          float: right;
          margin-right: -35px;
        }
      }
      &.sub{
        background-color: #cbcbcb;
      }
      &.null{
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        background-color: #fff;
      }
      &.bgblue{
        background-color: #eef8ff;
      }
    }
    td{
      background-color: #fff;
      padding: 12px;
      border-right: 1px solid #ebebeb;
      vertical-align: top;

      *:last-child{
        margin-bottom: 0;
      }
      *:first-child{
        margin-top: 0;
      }

      .kome{
        display: block;
        margin-top: 5px;
      }
      .category{
        width: 130px;
        display: inline-block;
      }
      .subtxt{
        width: 43px;
        display: inline-block;
        text-align: right;
        padding-right: 10px;
      }
      &.null{
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
      }
      &.bgblue{
        background-color: #eef8ff;
      }
    }
    &.company{
      border-left: none;
      border-top: none;

      tr{
        border-right: none;
      }
      th{
        border-right: none;
        background-color: #fff;
        font-weight: bold;
        //text-align: left;
        @if $type == sp{
          display: block;
          padding: 15px 0 0;
        }
      }
      td{
        border-right: none;
        @if $type == sp{
          display: block;
          padding: 6px 0 15px;
        }
      }
    }
    &.type2{
      border-left: none;
      th.title{
        text-align: left;
        border-right: none;
        background-color: #f6f6f6;
        &:after{
          content: none;
        }
      }
      tr{
        border-right: none;
      }
      th{
        background: none;
        border-right: none;
        position: relative;
        @if $type == pc{
          width: 170px;
        }
        @if $type == sp{
          width: 120px;
        }
        &:after{
          content: ":";
          color: #999999;
          display: inline-block;
          position: absolute;
          right: 0;
        }
      }
      td{
        border-right: none;
      }
    }
  }

  .scrollTable{
    overflow: auto;
    table{
      @if $type == sp{
        white-space: nowrap;
      }
    }
  }
  .scrolltext{
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
    text-align: right;
    &:before{
      content: "";
      background: url(../images/common/icon_scroll.png) no-repeat;
      width: 16px;
      height: 14px;
      background-size: contain;
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .common_btn{
    color: #fff;
    &[target="_blank"]:before,&[target="newwindow"]:before{
      content: "";
      background: url(../images/common/icon_blank.png) no-repeat;
      background-size: cover;
      width: 16px;
      height: 16px;
      display: inline-block;
      position: absolute;
      top: 12px;
      right: 12px;
    }
    &[href$=".pdf"]:after{
      content: none;
    }
  }
  .img_line2{
    @if $type == sp{
      &.spflex{
        display: flex;
        justify-content: center;
      }
    }
    @if $type == pc{
      display: flex;
      justify-content: center;
    }
    li{
      text-align: center;
      @if $type == sp{
        margin-bottom: 15px;
      }
      @if $type == pc{
        width: calc(50% - 8px);
        &:nth-of-type(odd){
          margin-right: 16px;
        }
      }
      img{
        max-width: 100%;
      }
    }
  }
  .img_line3{
    @if $type == sp{
    }
    @if $type == pc{
      display: flex;
      justify-content: center;
    }
    li{
      text-align: center;
      @if $type == sp{
        margin-bottom: 15px;
      }
      @if $type == pc{
        width: calc(33% - 8px);
        margin-right: 16px;
        &:nth-of-type(3n){
          margin-right: 0;
        }
      }
      img{
        max-width: 100%;
      }
    }
  }
  .imgInP{
    @if $type == pc{
      overflow: hidden;
    }
    .img{
      @if $type == sp{
        display: block;
        margin: 0 auto 20px;
      }
      @if $type == pc{
        max-width: 480px;
        img{
          width: 100%;
        }
      }
      .cap{
        display: block;
        text-align: left;
        font-size: 14px;
        margin-top: 10px;
      }
    }
    .img.left{
      @if $type == sp{
      }
      @if $type == pc {
        float: left;
        margin-right: 30px;
        margin-bottom: 15px;
      }
    }
    .img.right{
      @if $type == pc{
        float: right;
        margin-left: 30px;
        margin-bottom: 15px;
      }
    }
    &.col2{
      @if $type == pc{
        overflow: hidden;
      }
      .text{
        @if $type == sp{
          margin-bottom: 20px;
          display: block;
        }
        @if $type == pc{
          float: left;
          width: calc(50% - 30px);
          display: block;
          &.left{
            float: left;
            margin-right: 30px;
          }
          &.right{
            float: right;
            margin-left: 30px;
          }
        }
        .h3{
          &:first-of-type{
            margin-top: 0;
          }
          + .commonTable{
            margin-top: 30px;
            @if $type == sp{
              margin-top: 1em;
            }
          }
        }
      }
      .img{
        max-width: 100%;
        @if $type == pc{
          width: calc(50% - 30px);
        }
      }
    }
    &.sp_img_btm{
      @if $type == sp{
        display: flex;
        flex-wrap: wrap;
        .img{
          order: 2;
          margin: 20px auto 0;
        }
        .text{
          flex-basis: 100%;
          order: 1;
        }
      }
    }
  }
  .color_box{
    background-color: #f2f5f5;
    padding: 40px 20px 30px;
    margin-top: 10px;
    @if $type == sp{
      margin-top: 30px;
    }
    .title{
      font-size: 20px;
      margin-top: -55px;
      font-weight: bold;
      padding: 0 15px;
    }
    .ss_service_list{
      padding: 0 15px;
      margin-top: 1em;
    }
  }
}//contents


.sample{
  @if $type == sp{
  }
  @if $type == pc{
  }
}

/* common class */

.pointerNone{
  cursor: default;
  text-decoration: none;
}

.alignC{text-align: center!important;}
.alignL{text-align: left!important;}
.alignR{text-align: right!important;}
.Center{margin: 0 auto!important;}
.ovh{overflow: hidden!important;}
.mw100{max-width: 100%!important;
  width: auto!important;}
.w100p{width: 100%!important;}


.bold{font-weight: bold !important;}
.textred{color: red;}
.textblue{color: blue;}
.floatL{float: left;}
.floatR{float: right;}
.red{color: $c_base;}

.mB0{margin-bottom: 0!important;}
.mB5{margin-bottom: 5px!important;}
.mB10{margin-bottom: 10px!important;}
.mB20{margin-bottom: 20px!important;}
.mB30{margin-bottom: 30px!important;}
.mB40{margin-bottom: 40px!important;}
.mB50{margin-bottom: 50px!important;}
.mT0{margin-top: 0!important;}
.mT5{margin-top: 5px!important;}
.mT10{margin-top: 10px!important;}
.mT20{margin-top: 20px!important;}
.mT30{margin-top: 30px!important;}
.mT40{margin-top: 40px!important;}
.mT50{margin-top: 50px!important;}
.mL0{margin-left: 0px!important;}
.mL5{margin-left: 5px!important;}
.mL10{margin-left: 10px!important;}
.mL20{margin-left: 20px!important;}
.mL30{margin-left: 30px!important;}
.mL40{margin-left: 40px!important;}
.mL50{margin-left: 50px!important;}
.mR0{margin-right: 0px!important;}
.mR5{margin-right: 5px!important;}
.mR10{margin-right: 10px!important;}
.mR20{margin-right: 20px!important;}
.mR30{margin-right: 30px!important;}
.mR40{margin-right: 40px!important;}
.mR50{margin-right: 50px!important;}
